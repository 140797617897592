import { Box, Typography } from "@mui/material";
import { theme } from "theme";

const AboutUsText = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mt="-2rem"
      mb="10rem"
    >
      <Typography variant="h1">Hi!</Typography>
      <Typography variant="h1">My name is Cristiano</Typography>
      <Box
        width="5rem"
        height="3px"
        margin="2rem 0 1rem 0"
        backgroundColor={theme.palette.primary.dark}
      />
      <Typography variant="text">
        Welcome to Cristiano Oliveira Bookkeeping, where dedicated expertise
        meets personal attention. With seven years of experience in the
        wholesale sector, I offer a unique blend of proficiency and
        individualised service for your bookkeeping needs.
      </Typography>
      <Typography variant="text">
        Having navigated the complexities of a large wholesale company, I
        understand the challenges businesses face in maintaining accurate
        financial records. At Cristiano Oliveira Bookkeeping, I use my extensive
        knowledge to provide tailored bookkeeping services that meet the
        specific needs of your business.
      </Typography>
      <Typography variant="text">
        My commitment is to alleviate your bookkeeping burden, allowing you to
        focus on growing your business. Though a one-person operation, my
        experience ensures efficient and precise handling of your financial
        records.
      </Typography>
      <Typography variant="text">
        Choose Cristiano Oliveira Bookkeeping for a partner who values your
        business's individuality and understands your entrepreneurial
        challenges. Let me be your trusted ally in achieving financial success
        through personalised service.
      </Typography>
    </Box>
  );
};

export default AboutUsText;
