import { Box, useMediaQuery } from "@mui/material";
import ContactUsForm from "components/mobile/ContactUsForm";
import { pageWidth } from "constants/pageWidth";
import { useEffect } from "react";

const ContactUs = () => {
  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box display="flex" flexDirection="column" width="100%" margin="auto">
      <Box m="-4rem 2rem 0 2rem">
        <ContactUsForm />
      </Box>
    </Box>
  );
};

export default ContactUs;
