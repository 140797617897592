import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { theme } from "theme";
import bookkeepingLogo from "assets/icon_bookkeeping.svg";
import payrollLogo from "assets/icon_payroll.svg";
import managementAccountsLogo from "assets/icon_management_accounts.svg";
import { useNavigate } from "react-router-dom";

const HomeServices = () => {
  const navigate = useNavigate();

  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box display="flex">
      {/* LEFT */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        width="45%"
      >
        <Typography variant="h1">Simplify</Typography>
        <Typography variant="h1">Your Bookkeeping</Typography>
        <Box
          width="5rem"
          height="3px"
          margin="2rem 0 1rem -0.5rem"
          backgroundColor={theme.palette.primary.dark}
        />
        <Typography variant="mainText">
          Leave the bookkeeping to us and focus on growing your <br />
          business.
        </Typography>
        <Typography variant="mainText">
          Contact us for a personalised consultation!
        </Typography>
        <Box display="flex" gap="2rem" marginTop="4rem">
          <Button
            variant="contained"
            onClick={() => navigate("/about-us")}
            sx={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 0,
              textTransform: "capitalize",
              padding: "1rem 2rem",
            }}
          >
            Learn More
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate("/contact-us")}
            sx={{
              color: theme.palette.primary.dark,
              borderRadius: 0,
              borderColor: theme.palette.primary.dark,
              textTransform: "capitalize",
              padding: "1rem 2rem",
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
      {/* RIGHT */}
      <Box
        display="flex"
        justifyContent="flex-end"
        flexWrap="wrap"
        gap="0.5rem"
        width="65%"
      >
        <Box>
          <Box
            position="absolute"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            backgroundColor={theme.palette.primary.light}
            mt="2rem"
            mr="1rem"
          >
            <img src={bookkeepingLogo} alt="adding machine icon" width="25%" />
            <Typography variant="h2">Bookkeeping</Typography>
            <Typography variant="serviceText">
              We provide bookkeeping and VAT services that ensure accuracy and
              compliance for businesses of all sizes.
            </Typography>
          </Box>
          <Box
            position="relative"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            border={`1px solid ${theme.palette.primary.dark}`}
            mt="2.5rem"
            ml="0.5rem"
            mr="0.5rem"
            zIndex="-1"
          />
        </Box>
        <Box>
          <Box
            position="absolute"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            backgroundColor={theme.palette.primary.light}
          >
            <img src={payrollLogo} alt="payroll icon" width="25%" />
            <Typography variant="h2">Payroll</Typography>
            <Typography variant="serviceText">
              Our Payroll and Pensions service ensures smooth, accurate
              management and alleviates the burden for businesses of all sizes.
            </Typography>
          </Box>
          <Box
            position="relative"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            border={`1px solid ${theme.palette.primary.dark}`}
            mt="0.5rem"
            ml="0.5rem"
            zIndex="-1"
          />
        </Box>
        <Box>
          <Box
            position="absolute"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            backgroundColor={theme.palette.primary.light}
            mt="1rem"
          >
            <img
              src={managementAccountsLogo}
              alt="management icon"
              width="25%"
            />
            <Typography variant="h2">Management Accounts</Typography>
            <Typography variant="serviceText">
              Elevate your decision-making with our Management Accounts
              services, providing timely, insightful financial reports tailored
              for strategic planning.
            </Typography>
          </Box>
          <Box
            position="relative"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            border={`1px solid ${theme.palette.primary.dark}`}
            mt="1.5rem"
            ml="0.5rem"
            mr="0.5rem"
            zIndex="-1"
          />
        </Box>
        <Box>
          <Box
            position="absolute"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            backgroundColor={theme.palette.primary.light}
            mt="-1.5rem"
          >
            <Typography variant="h2">Other Services</Typography>
            <Typography variant="serviceText">
              In addition to standard bookkeeping, we offer specialised services
              like Credit Control and Debt Recovery, providing comprehensive
              support to enhance your financial management.
            </Typography>
          </Box>
          <Box
            position="relative"
            width="260px"
            height="260px"
            p="1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            border={`1px solid ${theme.palette.primary.dark}`}
            mt="-1rem"
            ml="0.5rem"
            zIndex="-1"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeServices;
