import { Box, Typography, Button } from "@mui/material";
import { theme } from "theme";
import img from "assets/Grupo 267.svg";
import { useNavigate } from "react-router-dom";

const AboutUsHome = () => {
  const navigate = useNavigate();
  return (
    <Box>
      {/* BOX LEFT */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "100dvw",
          height: "32rem",
          top: "0",
          left: "0",
          borderBottomRightRadius: "4rem",
          zIndex: "-1",
        }}
      />
      <Box display="flex" alignItems="space-between">
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mt="5rem"
        >
          <Typography variant="h1White">Explore my Journey</Typography>
          <Typography variant="h1White">and Expertise</Typography>
          <Box
            width="5rem"
            height="3px"
            margin="2rem 0 1rem 0"
            backgroundColor={theme.palette.primary.dark}
          />
          <Button
            variant="outlined"
            onClick={() => navigate("/contact-us")}
            sx={{
              color: "#ffffff",
              borderRadius: 0,
              borderColor: "#ffffff",
              textTransform: "capitalize",
              padding: "1rem 2rem",
            }}
          >
            Get Started Today
          </Button>
        </Box>
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <img src={img} alt="" width="90%" />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUsHome;
