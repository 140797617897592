import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import { TfiMenu } from "react-icons/tfi";
import { BsEnvelopeFill } from "react-icons/bs";
import { SiWhatsapp } from "react-icons/si";
import logoNav from "assets/logo_white.png";
import logoNavColor from "assets/logo_color.png";
import { IconContext } from "react-icons";
import { theme } from "theme";
import { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

const Menu = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [open, toggleMenu] = useState(false);

  const menu = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "About Us", path: "/about-us" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m="2rem"
      >
        <Box width="60px" height="60px">
          <img
            src={
              location.pathname === "/services" ||
              location.pathname === "/contact-us"
                ? logoNavColor
                : logoNav
            }
            alt="logo nav"
            width="60px"
            height="60px"
          />
        </Box>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <IconButton onClick={() => toggleMenu(!open)}>
            <IconContext.Provider
              value={{
                color:
                  location.pathname === "/contact-us"
                    ? theme.palette.primary.dark
                    : "white",
              }}
            >
              <TfiMenu />
            </IconContext.Provider>
          </IconButton>
        </Box>
      </Box>

      <Box
        position="absolute"
        display={open ? "flex" : "none"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
        top="0"
        left="0"
        backgroundColor="#ffffff"
        zIndex="99999"
        gap="2rem"
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0, margin: "2rem" }}
          onClick={() => toggleMenu(!open)}
        >
          <IconContext.Provider
            value={{
              color: theme.palette.primary.dark,
            }}
          >
            <IoCloseSharp />
          </IconContext.Provider>
        </IconButton>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          alignItems="center"
          gap="2rem"
        >
          {menu.map((item, index) => (
            <Button
              key={index}
              onClick={() => {
                navigate(item.path);
                toggleMenu(!open);
              }}
              sx={{
                fontSize: "1rem",
                textTransform: "capitalize",
                color: theme.palette.primary.dark,
                cursor: "pointer",
              }}
            >
              {item.name}
            </Button>
          ))}
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            gap="2rem"
          >
            <IconButton onClick={() => navigate("contact-us")}>
              <IconContext.Provider
                value={{
                  color: theme.palette.primary.dark,
                }}
              >
                <BsEnvelopeFill />
              </IconContext.Provider>
            </IconButton>
            <IconButton>
              <IconContext.Provider
                value={{
                  color: theme.palette.primary.dark,
                }}
              >
                <a href="tel:+447396821179">
                  <SiWhatsapp />
                </a>
              </IconContext.Provider>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Menu;
