import { Box, useMediaQuery } from "@mui/material";
import ServicesHome from "components/desktop/ServicesHome";
import ServicesServices from "components/desktop/ServicesServices";
import Feedback from "components/desktop/Feedback";
import { pageWidth } from "constants/pageWidth";

const Services = () => {
  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box display="flex" flexDirection="column" width="100%" margin="auto">
      <Box m="0 2rem">
        <ServicesHome />
        <ServicesServices />
        {/* <Feedback /> */}
      </Box>
    </Box>
  );
};

export default Services;
