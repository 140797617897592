import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Menu from "components/mobile/Menu";
import Footer from "components/mobile/Footer";

const LayoutMobile = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Menu />
      <Outlet />
      <Footer />
    </Box>
  );
};

export default LayoutMobile;
