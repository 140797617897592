import { Box, useMediaQuery } from "@mui/material";
import { pageWidth } from "constants/pageWidth";
import { useEffect } from "react";

import AboutUsHome from "components/mobile/AboutUsHome";
import AboutUsText from "components/mobile/AboutUsText";

const AboutUs = () => {
  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box display="flex" flexDirection="column" width="100%" margin="auto">
      <Box m="0 2rem">
        <AboutUsHome />
        <AboutUsText />
      </Box>
    </Box>
  );
};

export default AboutUs;
