import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import aboutMeImg from "assets/Grupo 267.svg";
import { theme } from "theme";
import { useNavigate } from "react-router-dom";

const HomeAboutUs = () => {
  const navigate = useNavigate();

  const mediaQuery = useMediaQuery("(max-width:650px)");

  return (
    <Box
      display="flex"
      mt="10rem"
      flexDirection={mediaQuery ? "column" : "row"}
    >
      <Box width={mediaQuery ? "100%" : "50%"} display="flex">
        <Box position="relative">
          <img
            src={aboutMeImg}
            alt="about me"
            width={mediaQuery ? "50%" : "100%"}
            height="100%"
          />
        </Box>
        <Box
          position="absolute"
          backgroundColor={theme.palette.primary.dark}
          width="35%"
          height={mediaQuery ? "20%" : "61%"}
          left="0"
          mt="2.4%"
          zIndex="-1"
          sx={{ borderTopRightRadius: "90px" }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width={mediaQuery ? "100%" : "40%"}
        alignItems="flex-start"
        ml="auto"
        mt="2rem"
      >
        <Typography variant="h1">About Me</Typography>
        <Box
          width="5rem"
          height="3px"
          margin="2rem 0 1rem 0rem"
          backgroundColor={theme.palette.primary.dark}
        />
        <Typography variant="aboutUsText" mb="1.5rem">
          Welcome to Cristiano Oliveira Bookkeeping, where dedicated expertise
          meets personal attention. With seven years of experience in the
          wholesale sector, I offer a unique blend of proficiency and
          individualised service for your bookkeeping needs.
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate("/about-us")}
          sx={{
            color: theme.palette.primary.dark,
            borderRadius: 0,
            borderColor: theme.palette.primary.dark,
            textTransform: "capitalize",
            padding: "1rem 2rem",
          }}
        >
          See More
        </Button>
      </Box>
    </Box>
  );
};

export default HomeAboutUs;
