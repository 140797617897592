import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#E5F3F8",
      main: "#008ABB",
      dark: "#054C70",
    },
  },
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontSize: "2.25rem",
      fontWeight: "bold",
      color: "#008ABB",
      letterSpacing: "2px",
      borderBottom: "1.5rem solid #E5F3F8",
      lineHeight: ".5rem",
      margin: "0.75rem -0.5rem",
      padding: "0 0.5rem",
    },
    h1White: {
      fontFamily: "Montserrat",
      color: "#ffffff",
      fontSize: "2.25rem",
      fontWeight: "bold",
      letterSpacing: "2px",
      borderBottom: "1.5rem solid #3F99BE",
      lineHeight: ".5rem",
      margin: "0.75rem -0.5rem",
      padding: "0 0.5rem",
    },
    h1Dark: {
      fontFamily: "Montserrat",
      display: "block",
      fontSize: "2.25rem",
      fontWeight: "bold",
      color: "#054C70",
      letterSpacing: "2px",
      borderBottom: "1.5rem solid #E5F3F8",
      lineHeight: "0.5rem",
      margin: "0.125rem -0.5rem",
      padding: "0 0.5rem",
    },
    h2: {
      fontFamily: "Montserrat",
      fontSize: "1.65rem",
      fontWeight: "bold",
      color: "#054C70",
      textAlign: "center",
    },
    mainText: {
      fontFamily: "Montserrat",
      lineHeight: 2,
    },
    serviceText: {
      fontFamily: "Montserrat",
      textAlign: "center",
      fontSize: "0.9rem",
    },
    aboutUsText: {
      fontFamily: "Montserrat",
      lineHeight: "2rem",
    },
    contactUsTextWhite: {
      fontFamily: "Montserrat",
      color: "#ffffff",
      lineHeight: "2rem",
    },
    contactUsText: {
      fontFamily: "Montserrat",
      color: "#000000",
      lineHeight: "2rem",
    },
    text: {
      fontFamily: "Montserrat",
      fontSize: "1rem",
      lineHeight: "2rem",
      padding: "1rem 0",
    },
  },
});
