import { Box } from "@mui/material";
import HomeLogo from "components/mobile/HomeLogo";
import HomeServices from "components/mobile/HomeServices";
import HomeAboutUs from "components/mobile/HomeAboutUs";
import { pageWidth } from "constants/pageWidth";
import Feedback from "components/mobile/Feedback";
import ContactUsForm from "components/mobile/ContactUsForm";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box m="0 2rem">
        <HomeLogo />
        <HomeServices />
        <HomeAboutUs />
        {/* <Feedback /> */}
        <ContactUsForm />
      </Box>
    </Box>
  );
};

export default Home;
