import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { theme } from "theme";
import img from "assets/Grupo 95.svg";
import { useNavigate } from "react-router-dom";

const ServicesHome = () => {
  const navigate = useNavigate();

  const isTablet = useMediaQuery("(max-width:1130px)");
  const isMobile = useMediaQuery("(max-width:710px)");

  return (
    <Box>
      {/* BOX RIGHT */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "8rem",
          height: "8rem",
          top: "0",
          right: "0",
          borderBottomLeftRadius: "4rem",
          zIndex: "-1",
        }}
      />
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        height="calc(100dvh - 7rem)"
      >
        <Box
          width={isMobile ? "100%" : "50%"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {isTablet ? (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h1Dark">Optimise your</Typography>
              <br />
              <Typography variant="h1Dark">finances with</Typography>
              <br />
              <Typography variant="h1Dark">our services</Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h1Dark">Optimise your finances</Typography>
              <br />
              <Typography variant="h1Dark">with our services</Typography>
            </Box>
          )}

          <Box
            width="5rem"
            height="3px"
            margin="2rem 0 1rem 0rem"
            backgroundColor={theme.palette.primary.dark}
          />
          <Button
            variant="outlined"
            onClick={() => navigate("/contact-us")}
            sx={{
              color: theme.palette.primary.dark,
              borderRadius: 0,
              borderColor: theme.palette.primary.dark,
              textTransform: "capitalize",
              padding: "1rem 2rem",
            }}
          >
            Get Started Today
          </Button>
        </Box>
        <Box width="50%" mt="2rem">
          <img src={img} alt="" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesHome;
