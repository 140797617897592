import { Box, Typography } from "@mui/material";
import qbLogo from "assets/2023-QB-Online.png";

const Footer = () => {
  return (
    <Box>
      <Box
        backgroundColor="#E5F3F8"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <img
          src={qbLogo}
          alt="Quickbooks ProAdvisor Certificate"
          height="120px"
          m="50px"
          display="block"
        />
      </Box>
      <Box backgroundColor="#054C70" width="100vw">
        <Typography
          fontSize="0.85rem"
          textAlign="center"
          color="#ffffff"
          p="1.5rem"
        >
          Copyright 2024, All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
