import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SiWhatsapp } from "react-icons/si";
import { BsEnvelopeFill } from "react-icons/bs";
import logoNav from "assets/logo_white.png";
import logoNavColor from "assets/logo_color.png";
import { IconContext } from "react-icons";
import { theme } from "theme";
import { pageWidth } from "constants/pageWidth";

const Menu = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const menu = [
    { name: "Home", path: "/" },
    { name: "Services", path: "/services" },
    { name: "About Us", path: "/about-us" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={isMobile ? "95%" : isTablet ? "85%" : pageWidth}
      maxWidth="1344px"
      m="2rem 0"
    >
      <Box width="60px" height="60px">
        <img
          src={
            location.pathname === "/services" ||
            location.pathname === "/contact-us"
              ? logoNavColor
              : logoNav
          }
          alt="logo nav"
          width="60px"
          height="60px"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        gap="3.5rem"
      >
        {menu.map((item, index) => (
          <Button
            key={index}
            onClick={() => navigate(item.path)}
            sx={{
              fontSize: "1rem",
              textTransform: "capitalize",
              color:
                location.pathname === "/about-us"
                  ? "#ffffff"
                  : theme.palette.primary.dark,
              cursor: "pointer",
            }}
          >
            {item.name}
          </Button>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <IconButton onClick={() => navigate("contact-us")}>
          <IconContext.Provider
            value={{
              color:
                location.pathname === "/contact-us"
                  ? theme.palette.primary.dark
                  : "white",
            }}
          >
            <BsEnvelopeFill />
          </IconContext.Provider>
        </IconButton>
        <IconButton>
          <IconContext.Provider
            value={{
              color:
                location.pathname === "/contact-us"
                  ? theme.palette.primary.dark
                  : "white",
            }}
          >
            <a href="tel:+447396821179">
              <SiWhatsapp />
            </a>
          </IconContext.Provider>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Menu;
