import { Box, Typography, Button, useMediaQuery } from "@mui/material";
import { theme } from "theme";
import img from "assets/Grupo 267.svg";
import { useNavigate } from "react-router-dom";

const AboutUsHome = () => {
  const navigate = useNavigate();

  const sm = useMediaQuery("(max-width:970px)");
  const xs = useMediaQuery("(max-width:700px)");

  return (
    <Box>
      {/* BOX LEFT */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "100dvw",
          height: "32rem",
          top: "0",
          left: "0",
          borderBottomRightRadius: "4rem",
          zIndex: "-1",
          pb: "1rem",
        }}
      />
      <Box display="flex" alignItems="space-between">
        <Box
          width="50%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          {xs ? (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h1White">Explore</Typography>
              <Typography variant="h1White">my</Typography>
              <Typography variant="h1White">Journey</Typography>
              <Typography variant="h1White">and</Typography>
              <Typography variant="h1White">Expertise</Typography>
            </Box>
          ) : sm ? (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h1White">Explore my</Typography>
              <Typography variant="h1White">Journey</Typography>
              <Typography variant="h1White">and Expertise</Typography>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h1White">Explore my Journey</Typography>
              <Typography variant="h1White">and Expertise</Typography>
            </Box>
          )}

          <Box
            width="5rem"
            height="3px"
            margin="2rem 0 1rem 0"
            backgroundColor={theme.palette.primary.dark}
          />
          <Button
            variant="outlined"
            onClick={() => navigate("/contact-us")}
            sx={{
              color: "#ffffff",
              borderRadius: 0,
              borderColor: "#ffffff",
              textTransform: "capitalize",
              padding: "1rem 1.5rem",
            }}
          >
            Get Started Today
          </Button>
        </Box>
        <Box
          width="50%"
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <img src={img} alt="" width="90%" />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUsHome;
