import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { theme } from "theme";
import bookkeepingImg from "assets/icon_bookkeeping.svg";
import payrollImg from "assets/icon_payroll.svg";
import managementImg from "assets/icon_management_accounts.svg";

const ServicesServices = () => {
  const isTablet = useMediaQuery("(max-width:760px)");
  const isMobile = useMediaQuery("(max-width:625px)");
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb="1rem"
    >
      <Typography variant="h1">Services</Typography>
      <Box
        width="5rem"
        height="3px"
        margin="2rem 0 1rem 0rem"
        backgroundColor={theme.palette.primary.dark}
      />
      {isTablet ? (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            gap="1rem"
            flexWrap="wrap"
          >
            <Box
              width={isMobile ? "100%" : "40%"}
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap="1rem"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2">Bookkeeping</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Our professional bookkeeping and VAT services streamline your
                  financial processes, ensuring accuracy, compliance, and peace
                  of mind. We offer tailored solutions for small startups,
                  growing businesses, and established enterprises.
                </Typography>
              </Box>
              <Box ml="auto">
                <img src={bookkeepingImg} alt="" width="90px" />
              </Box>
            </Box>
            <Box
              width={isMobile ? "100%" : "40%"}
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2">Payroll</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Ensure smooth and accurate management of your workforce with
                  our Payroll and Pensions service. Designed to alleviate the
                  burden of payroll processing and pension administration, our
                  comprehensive solutions cater to businesses of all sizes.
                </Typography>
              </Box>
              <Box ml="auto">
                <img src={payrollImg} alt="" width="90px" />
              </Box>
            </Box>
            <Box
              width={isMobile ? "100%" : "40%"}
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2"> Management Accounts</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Elevate your business decision-making with our Management
                  Accounts services. We provide timely and insightful financial
                  reporting, giving you a comprehensive view of your company's
                  performance. Tailored to meet your specific needs, our
                  services empower you with the information necessary for
                  strategic planning and informed decision-making.
                </Typography>
              </Box>
              <Box ml="auto" pt="2rem">
                <img src={managementImg} alt="" width="90px" />
              </Box>
            </Box>
            <Box
              width={isMobile ? "100%" : "40%"}
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap=".125rem">
                <Typography variant="h2" sx={{ p: "1.5rem" }}>
                  Other Services
                </Typography>
                <Typography
                  variant="serviceText"
                  sx={{ textAlign: "left", p: "1.5rem" }}
                >
                  In addition to standard bookkeeping tasks, we offer a range of
                  specialised services designed to cater to the unique financial
                  needs of your business like Credit Control and Debt Recovery.
                  Our comprehensive suite of bookkeeping services goes beyond
                  the basics, providing you with the support necessary to
                  enhance your financial management.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box display="flex" justifyContent="space-between" gap="1rem">
            <Box
              width="33%"
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap="1rem"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2">Bookkeeping</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Our professional bookkeeping and VAT services streamline your
                  financial processes, ensuring accuracy, compliance, and peace
                  of mind. We offer tailored solutions for small startups,
                  growing businesses, and established enterprises.
                </Typography>
              </Box>
              <Box ml="auto">
                <img src={bookkeepingImg} alt="" width="90px" />
              </Box>
            </Box>
            <Box
              width="33%"
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2">Payroll</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Ensure smooth and accurate management of your workforce with
                  our Payroll and Pensions service. Designed to alleviate the
                  burden of payroll processing and pension administration, our
                  comprehensive solutions cater to businesses of all sizes.
                </Typography>
              </Box>
              <Box ml="auto">
                <img src={payrollImg} alt="" width="90px" />
              </Box>
            </Box>
            <Box
              width="33%"
              backgroundColor={theme.palette.primary.light}
              p="1.5rem"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column" gap="1.75rem">
                <Typography variant="h2"> Management Accounts</Typography>
                <Typography variant="serviceText" sx={{ textAlign: "left" }}>
                  Elevate your business decision-making with our Management
                  Accounts services. We provide timely and insightful financial
                  reporting, giving you a comprehensive view of your company's
                  performance. Tailored to meet your specific needs, our
                  services empower you with the information necessary for
                  strategic planning and informed decision-making.
                </Typography>
              </Box>
              <Box ml="auto" pt="2rem">
                <img src={managementImg} alt="" width="90px" />
              </Box>
            </Box>
          </Box>
          <Box
            width="100%"
            backgroundColor={theme.palette.primary.light}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="10rem"
            mt="1rem"
            pb="1.5rem"
          >
            <Typography variant="h2" sx={{ p: "1.5rem" }}>
              Other Services
            </Typography>
            <Typography
              variant="serviceText"
              sx={{ textAlign: "left", p: "1.5rem" }}
            >
              In addition to standard bookkeeping tasks, we offer a range of
              specialised services designed to cater to the unique financial
              needs of your business like Credit Control and Debt Recovery. Our
              comprehensive suite of bookkeeping services goes beyond the
              basics, providing you with the support necessary to enhance your
              financial management.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ServicesServices;
