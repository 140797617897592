import { Box, Icon } from "@mui/material";
import { theme } from "theme";
import logo from "assets/co_bookkeeping_full_logo.png";
import { IconContext } from "react-icons";
import { TbHandFinger } from "react-icons/tb";
import "animate.css";

const HomeLogo = () => {
  return (
    <Box>
      {/* BOX LEFT */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dark,
          position: "absolute",
          width: "8rem",
          height: "8rem",
          top: "0",
          left: "0",
          borderBottomRightRadius: "4rem",
          zIndex: "-1",
        }}
      />
      {/* BOX RIGHT */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "8rem",
          height: "8rem",
          top: "0",
          right: "0",
          borderBottomLeftRadius: "4rem",
          zIndex: "-1",
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="calc(100dvh - 7rem)"
      >
        <img src={logo} alt="Logo" maxWidth="100px" width="95%" />
        <Icon
          className="animate__animated animate__bounce"
          sx={{ width: "5rem", height: "5rem", mt: "8rem", mb: "4rem" }}
        >
          <IconContext.Provider value={{ size: "5rem" }}>
            <TbHandFinger />
          </IconContext.Provider>
        </Icon>
      </Box>
    </Box>
  );
};

export default HomeLogo;
