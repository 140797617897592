import { Box, useMediaQuery } from "@mui/material";
import ServicesHome from "components/desktop/ServicesHome";
import ServicesServices from "components/desktop/ServicesServices";
import Feedback from "components/desktop/Feedback";
import { pageWidth } from "constants/pageWidth";

const Services = () => {
  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMobile ? "95%" : isTablet ? "85%" : pageWidth}
      maxWidth="1344px"
      margin="auto"
    >
      <ServicesHome />
      <ServicesServices />
      {/* <Feedback /> */}
    </Box>
  );
};

export default Services;
