import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Menu from "components/desktop/Menu";
import Footer from "components/desktop/Footer";

const Layout = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Menu />
      <Outlet />
      <Footer />
    </Box>
  );
};

export default Layout;
