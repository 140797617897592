import { Box, useMediaQuery } from "@mui/material";
import { pageWidth } from "constants/pageWidth";
import { useEffect } from "react";

import AboutUsHome from "components/desktop/AboutUsHome";
import AboutUsText from "components/desktop/AboutUsText";

const AboutUs = () => {
  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMobile ? "95%" : isTablet ? "85%" : pageWidth}
      maxWidth="1344px"
      margin="auto"
    >
      <AboutUsHome />
      <AboutUsText />
    </Box>
  );
};

export default AboutUs;
