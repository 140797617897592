import Home from "pages/desktop/home";
import Layout from "pages/desktop/layout";
import Services from "pages/desktop/services";
import AboutUs from "pages/desktop/AboutUs";
import ContactUs from "pages/desktop/ContactUs";
import HomeMobile from "pages/mobile/home";
import LayoutMobile from "pages/mobile/layout";
import ServicesMobile from "pages/mobile/services";
import AboutUsMobile from "pages/mobile/AboutUs";
import ContactUsMobile from "pages/mobile/ContactUs";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        { path: "/services", element: <Services /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/contact-us", element: <ContactUs /> },
      ],
    },
  ]);
  const routerMobile = createBrowserRouter([
    {
      path: "/",
      element: <LayoutMobile />,
      errorElement: <LayoutMobile />,
      children: [
        { path: "/", element: <HomeMobile /> },
        { path: "/services", element: <ServicesMobile /> },
        { path: "/about-us", element: <AboutUsMobile /> },
        { path: "/contact-us", element: <ContactUsMobile /> },
      ],
    },
  ]);

  const isMobile = useMediaQuery("(max-width:1250px)");

  return (
    <div className="app">
      <RouterProvider router={isMobile ? routerMobile : router} />
    </div>
  );
}

export default App;
