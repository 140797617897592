import { Box, useMediaQuery } from "@mui/material";
import HomeLogo from "components/desktop/HomeLogo";
import HomeServices from "components/desktop/HomeServices";
import HomeAboutUs from "components/desktop/HomeAboutUs";
import { pageWidth } from "constants/pageWidth";
import Feedback from "components/desktop/Feedback";
import ContactUsForm from "components/desktop/ContactUsForm";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isTablet = useMediaQuery("(max-width:1595px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={isMobile ? "95%" : isTablet ? "85%" : pageWidth}
      maxWidth="1344px"
      margin="auto"
    >
      <HomeLogo />
      <HomeServices />
      <HomeAboutUs />
      {/* <Feedback /> */}
      <ContactUsForm />
    </Box>
  );
};

export default Home;
