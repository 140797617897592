import { Box, Typography, IconButton, TextField, Button } from "@mui/material";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaLocationDot,
} from "react-icons/fa6";
import { BsEnvelopeFill } from "react-icons/bs";
import { SiWhatsapp } from "react-icons/si";
import { theme } from "theme";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactUsForm = () => {
  const location = useLocation();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_v0kxg2o", "template_hhw7fuf", e.target, {
        publicKey: "dbUJiPoSCfDV6m4cT",
      })
      .then(() => {
        e.target.reset();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Box mt="4rem">
      <Box display="flex">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          position="relative"
          p="6rem 0 6rem 0"
          width="90%"
        >
          <Typography
            variant={location.pathname === "/contact-us" ? "h1" : "h1White"}
          >
            Get in Touch
          </Typography>
          <Box
            width="5rem"
            height="3px"
            margin="2rem 0 1rem 0rem"
            backgroundColor={theme.palette.primary.dark}
          />
          <Typography
            variant={
              location.pathname === "/contact-us"
                ? "contactUsText"
                : "contactUsTextWhite"
            }
            pr="5rem"
            pb="5rem"
          >
            Please contact us at your earliest convenience to discuss your
            requirements and obtain a customised quote. We look forward to
            assisting you.
          </Typography>
          {location.pathname === "/contact-us" ? (
            <Box
              mt="-2.5rem"
              mb="2.5rem"
              display="flex"
              flexDirection="column"
              gap="1rem"
            >
              <Box display="flex" gap="1rem" alignItems="center">
                <FaLocationDot size="2rem" color={theme.palette.primary.dark} />
                <Typography variant="contactUsText">
                  Eastbourne, East Sussex, United Kingdom
                </Typography>
              </Box>
              <Box display="flex" gap="1rem" alignItems="center">
                <BsEnvelopeFill
                  size="2rem"
                  color={theme.palette.primary.dark}
                />
                <Typography variant="contactUsText">
                  info@oliveira-bookkeeping.com
                </Typography>
              </Box>
              <Box display="flex" gap="1rem" alignItems="center">
                <SiWhatsapp size="2rem" color={theme.palette.primary.dark} />
                <Typography variant="contactUsText">+44 7396 821179</Typography>
              </Box>
            </Box>
          ) : null}
          <Typography
            variant={location.pathname === "/contact-us" ? "h1" : "h1White"}
          >
            Stay Connected
          </Typography>
          <Box
            width="5rem"
            height="3px"
            margin="2rem 0 1rem 0rem"
            backgroundColor={theme.palette.primary.dark}
          />
          <Box>
            <IconButton>
              <a
                href="https://www.facebook.com/profile.php?id=61554972129545"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF
                  color={
                    location.pathname === "/contact-us"
                      ? theme.palette.primary.dark
                      : "#ffffff"
                  }
                />
              </a>
            </IconButton>
            {/* <IconButton>
              <a
                href="https://www.linkedin.com/in/cristiano-oliveira-19a94146/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram color={
                    location.pathname === "/contact-us"
                      ? theme.palette.primary.dark
                      : "#ffffff"
                  } />
              </a>
            </IconButton> */}
            <IconButton>
              <a
                href="https://www.linkedin.com/in/cristiano-oliveira-19a94146/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn
                  color={
                    location.pathname === "/contact-us"
                      ? theme.palette.primary.dark
                      : "#ffffff"
                  }
                />
              </a>
            </IconButton>
          </Box>
        </Box>
        <Box
          position="absolute"
          backgroundColor={
            location.pathname === "/contact-us"
              ? "#ffffff"
              : theme.palette.primary.main
          }
          width="52%"
          height="90%"
          left="0"
          zIndex="-1"
          sx={{ borderTopRightRadius: "90px" }}
        />
        <form onSubmit={sendEmail} style={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="column"
            p="6rem 0 6rem 0"
            gap="2rem"
            ml="auto"
            width="80%"
          >
            <TextField
              type="text"
              label="Name"
              id="name"
              name="name"
              required
            />
            <TextField
              label="Email"
              id="email"
              name="email"
              type="email"
              required
            />
            <TextField
              type="text"
              label="Subject"
              id="subject"
              name="subject"
              required
            />
            <TextField
              label="Message"
              type="text"
              id="message"
              name="message"
              required
              multiline
              rows={4}
            />
            <Box m="0 0 0 auto" display="flex">
              <Button
                type="submit"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  padding: "1rem 5rem",
                  fontFamily: "Montserrat",
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ContactUsForm;
